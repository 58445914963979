import React, {useEffect, useState} from "react"

import {
    Header,
    Footer,
    SEO,
    MobileOrientation,
    CartProvider,
    Unsubscribe,
} from "components"

import Theme from "../theme"
import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"
import {addLocaleData, IntlProvider} from "react-intl";
import PageTransition from "gatsby-v2-plugin-page-transitions";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import LeafLeft from "../images/leaf left.png";
import LeafRight from "../images/leaf right.png";
import {graphql} from "gatsby";

addLocaleData([...locale_en, ...locale_es])

export default ({ data, pageContext, location }) => {
    const langKey = pageContext.locale
    const i18nmessages = require(`../messages/index/${langKey}`)


    const pageMetadata = {
        title: data.site.siteMetadata.title,
        description:data.site.siteMetadata.description,
    }

    const {
        transitionTime,
        defaultTransitionTime,
    } = require("../../../constants/randomConsts")

    const queryString = require("query-string")
    const parsedQuery = queryString.parse(location.search)
    const userID=parsedQuery.ci
    return (
        <PageTransition
            transitionTime={
                parsedQuery.transition ? transitionTime : defaultTransitionTime
            }
        >
            <IntlProvider locale={langKey} messages={i18nmessages}>
                <React.Fragment>
                    <CssBaseline/>
                    <ThemeProvider theme={Theme}>
                        <MobileOrientation/>
                        <SEO siteMetadata={pageMetadata}/>
                        <CartProvider cartId={undefined}>

                            <Header
                                data={data.header.edges[0].node}
                                slug={pageContext.slug}
                            />

                            <Unsubscribe  userID={userID}/>

                            <Footer
                                data={data.footer.edges[0].node}
                                bgImgLeft={LeafLeft}
                                bgImgRight={LeafRight}
                                langKey={langKey}
                            />
                        </CartProvider>
                    </ThemeProvider>
                </React.Fragment>
            </IntlProvider>
        </PageTransition>
    )
}


export const pageQuery = graphql`
  query($locale: String!) {
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
